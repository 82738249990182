<template>
  <div>

    <section>
      <b-container>
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <b-card>
              <div v-if="admin">
                <div class="d-flex justify-content-between">
                  <b-avatar v-if="admin.image" variant="info" :src="admin.image"></b-avatar>
                  <b-avatar v-else variant="info" :text="getInitials"></b-avatar>
                  <b-button variant="primary" pill @click="impersonateUser(admin)">Inloggen als gebruiker</b-button>
                </div>
                <h2 style="margin-top: 1px;">{{ admin.fullname }}</h2>
                <div><a :href="`mailto:${admin.email}`">{{ admin.email }}</a></div>
                <div>{{ admin.phone }}</div>
                <b-form-checkbox @change="toggleSkipInvoicing" id="skip-invoicing" v-model="skipInvoicing" name="skip-invoicing">
                  Sla facturatie vanaf nu over
                </b-form-checkbox>
                <div class="d-flex mt-1">
                  <span>Aantal familieleden:</span>
                  <b-form-input v-model="childrenLimit" type="number"></b-form-input>
                  <b-button @click="updateChildren" variant="primary" class="ml-1">Opslaan</b-button>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
      <b-container>

        <b-card>

          <b-table class="mt-2" responsive borderless :items="invoices" :fields="invoiceTable.fields" sort-by="sent_date" :sort-desc="true">
            <template #cell(total)="row">
              {{ new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(row.item.total) }}
            </template>

            <template #cell(open)="row">
              {{ new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(row.item.open) }}
            </template>



            <template #cell(sent_date)="row">
              {{ new Intl.DateTimeFormat('nl-NL', { year: 'numeric', month: 'long', day: 'numeric' }).format(new Date(row.item.sent_date.replace(/-/g, '/').replace('T', ' '))) }}
            </template>

            <template #cell(action)="row">
              <b-button variant="primary" @click="openChangeOpenAmountModal(row.item)">Aanpassen</b-button>
              <b-button v-if="row.item.total > 0" variant="info" class="ml-1" @click="openCreditInvoiceModal(row.item)">Crediteren</b-button>
            </template>
          </b-table>
        </b-card>
      </b-container>
    </section>
    <b-modal id="invoice-amount-modal" title="Factuur open bedrag aanpassen" hide-footer>
      <h3>Hoeveel moet nog betaald worden in deze factuur?</h3>
      <div class="d-flex">
        <b-form-input type="number" v-model="invoiceAmount" placeholder="Bedrag" class="mr-1"></b-form-input>
        <b-button @click="updateInvoiceAmountOpen">Opslaan</b-button>
      </div>
    </b-modal>
    <b-modal id="credit-invoice-modal" title="Factuur crediteren" @ok="creditInvoice">
      <h3>Weet je zeker dat je de factuur wilt crediteren?</h3>
    </b-modal>


  </div>
</template>

<script>
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

// import store from '@/store'

export default {
  components: {
  },
  data() {

    return {
      admin: null,
      skipInvoicing: false,
      invoiceAmount: null,
      childrenLimit: null,
      invoices: [],
      invoiceSelected: {},
      invoiceTable: {
        fields: [
          { key: 'invoice_number', label: 'Nummer' },
          { key: 'total', label: 'Bedrag' },
          { key: 'open', label: 'Te betalen' },
          { key: 'sent_date', label: 'Datum' },
          { key: 'action', label: 'Actie', },
        ]
      }
    }
  },
  async mounted() {
    console.log("hello")
    if (this.$auth.hasRole(['superadmin']) === false) {
      this.$router.push("/")
    } else {
      await this.getAdmin();
      await this.getInvoices();
      await this.getChildren();
    }
  },
  computed: {
    getInitials() {
      if (this.admin) {
        return this.admin.firstname[0] + this.admin.lastname[0]
      } else {
        return ""
      }
    }
  },
  methods: {
    getAdmin() {
      this.$http.get(`/admin/admins/${this.$route.params.adminId}`).then(response => {
        console.log("skip invoicing", response.data.user.family.skip_invoicing)
        if (response.data) {
          this.admin = response.data.user;
          this.skipInvoicing = response.data.user.family.skip_invoicing;
        }
      });
    },
    getInvoices() {
      this.$http.post(`/admin/invoices`, {
        "admin_id": this.$route.params.adminId
      }).then(response => {
        this.invoices = response.data.invoices;

      });
    },
    openChangeOpenAmountModal(invoice) {
      this.invoiceSelected = invoice;
      this.invoiceAmount = invoice.open;
      this.$bvModal.show('invoice-amount-modal')
    },
    updateInvoiceAmountOpen() {
      this.$http.post(`/admin/invoices/open`, {
        "invoice_id": this.invoiceSelected.hashid,
        "admin_id": this.$route.params.adminId,
        "open": this.invoiceAmount
      }).then(() => {
        this.getInvoices();
        this.$bvModal.hide('invoice-amount-modal')
      });
    },
    openCreditInvoiceModal(invoice) {
      this.invoiceSelected = invoice;
      this.$bvModal.show('credit-invoice-modal')
    },
    creditInvoice() {
      this.$http.post(`/admin/invoices/credit`, {
        "invoice_id": this.invoiceSelected.hashid,
        "admin_id": this.$route.params.adminId
      }).then(() => {
        this.getInvoices();
        this.$bvModal.hide('credit-invoice-modal')
      });
    },
    toggleSkipInvoicing() {
      this.$http.post(`/admin/invoices/skip`, { skip_invoicing: this.skipInvoicing, admin_id: this.$route.params.adminId }).then(response => {
        if (response.data) {
          this.skipInvoicing = response.data.skip_invoicing
        }
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Factuur instellingen aangepast',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      });
    },
    impersonateUser(user) {
      this.$http.post("/admin/users/impersonate", { user_id: user.hashid }).then(response => {
        if (response.data) {
          this.$router.push("/")
        }
      });
    },
    getChildren() {
      this.$http.get(`/admin/children?admin_id=${this.$route.params.adminId}`).then(response => {
        this.childrenLimit = response.data.limit;
      });
    },
    updateChildren() {
      this.$http.post(`/admin/children`, { limit: this.childrenLimit, admin_id: this.$route.params.adminId }).then(response => {
        this.childrenLimit = response.data.limit;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Aantal kinderen aangepast ${response.data.limit}`,
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Er is iets fout gegaan',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
      });
    }
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped></style>
